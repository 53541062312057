<template>
    <div>
        <section id="content">
            <div class="content-wrap">
                <div class="container">

                    <div class="row gutter-40 col-mb-80">
                        <!-- Postcontent
                        ============================================= -->
                        <div class="postcontent col-lg-9">

                            <h3>Send us an Email</h3>

                            <div class="form-widget">

                                <div class="form-result"></div>

                                <form class="mb-0" id="contactus" @submit="sendEmail" novalidate="true">

                                    <div class="form-process">
                                        <div class="css3-spinner">
                                            <div class="css3-spinner-scaler"></div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label for="name">Name <small>*</small></label>
                                            <input type="text" id="name" name="name" v-model="name" class="sm-form-control required" />
                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label for="email">Email <small>*</small></label>
                                            <input type="email" id="email" name="email" v-model="email" class="required email sm-form-control" />
                                        </div>

                                        <div class="w-100"></div>
                                        
                                        <div class="col-md-4 form-group">
                                            <label for="contact">Phone <small>*</small></label>
                                            <input type="text" id="contact" name="contact" v-model="contact" class="sm-form-control" />
                                        </div>

                                        <div class="col-md-8 form-group">
                                            <label for="service">Service Needed</label>
                                            <select id="service" name="service" v-model="service" class="sm-form-control">
                                                <option value="">-- Select One --</option>
                                                <option value="Customer Support">Customer Support Needed</option>
                                                <option value="Technical Support">Technical Support Needed</option>
                                                <option value="Customer Experience">Customer Experience Needed</option>
                                                <option value="Real Estate">Real Estate Success Needed</option>
                                                <option value="Marketing">Marketing Assistance Needed</option>
                                                <option value="Sales">Sales Development Needed</option>
                                                <option value="Billing/Accounting">Billing and Accounting Assistance Needed</option>
                                                <option value="HR">HR Assistance Needed</option>
                                                <option value="Operational Assistance">Operational Assistance Needed</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div class="w-100"></div>

                                        <div class="col-12 form-group">
                                            <label for="message">Message <small>*</small></label>
                                            <textarea class="required sm-form-control" id="message" v-model="message" name="message" rows="6" cols="30"></textarea>
                                        </div>

                                        <div class="col-md-12 form-group">
                                            <p v-if="errors.length">
                                                <b>Please fill in the missing details: </b>
                                                <ul>
                                                    <li v-for="error in errors" :key="error" class="error">{{ error }}</li>
                                                </ul>
                                            </p>
                                        </div>

                                        <div class="col-12 form-group">
                                            <button class="button button-3d m-0" type="submit">Send Message</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div><!-- .postcontent end -->

                        <!-- Sidebar
                        ============================================= -->
                        <div class="sidebar col-lg-3">

                            <address>
                                <strong>Headquarters:</strong><br>
                                1220 Howard Avenue, Suite 220<br>
                                Burlingame, California 94010<br>
                            </address>
                            <abbr title="Email Address"><strong>Email:</strong></abbr> hello@eflexervices.com

                        </div><!-- .sidebar end -->
                    </div>

                </div>
            </div>
        </section><!-- #content end -->
    </div>
</template>

<script>
import emailjs from 'emailjs-com'
import{ init } from 'emailjs-com'
init("user_omZRSVBH1M8JlNRLhJMnP");
export default {
    data() {
        return {
			errors: [],
			name: '',
			contact: '',
			email: '',
			message: '',
            service: '',
			to_email: 'hello@eflexervices.com'
		}
    },
    methods: {
        sendEmail: function(e) {
            if(this.name && this.contact && this.email && this.message && this.service) {
                const templateParams = {
                    from_name: this.name,
                    from_email: this.email,
                    contact: this.contact,
                    message: this.message,
                    service: this.service,
                    to_email: this.to_email,
                }
                emailjs.send('service_d8057wo', 'template_a54rlgg', templateParams,
                'user_omZRSVBH1M8JlNRLhJMnP')
                this.$router.push('/thankyou')
            }
            this.errors = []
            if(!this.name) {
				this.errors.push('Name Required')
			}
			if(!this.contact) {
				this.errors.push('Phone Number Required')
			}
            if(!this.service) {
				this.errors.push('Service Needed Required')
			}
			if(!this.email) {
				this.errors.push('Email Required')
			} else if(!this.validEmail(this.email)) {
				this.errors.push('Valid Email Required')
			}
			if(!this.message) {
				this.errors.push("What message you want to send to us")
			}
			e.preventDefault()
        },
        validEmail: function(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email)
		},
    }
}
</script>