<template>
  <div>
    <section id="page-title" class="page-title-parallax page-title-center page-title-dark include-header" style="background-image: linear-gradient(to top, rgba(254,150,3,0.5), #39384D), url('/assets/images/services/services.jpeg'); background-size: cover; padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
        <div id="particles-line"></div>

        <div class="container clearfix mt-4">
            <div class="badge badge-pill border border-light text-light">Real Estate</div>
            <h1>Real Estate Success</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Real Estate</li>
            </ol>
        </div>

    </section>
    <section id="content">

			<div class="content-wrap pb-0">
                <!-- Main Content
				============================================= -->
				<div class="container">
					<div class="row align-items-end">
						<div class="col-lg-6">
							<div class="badge badge-pill badge-default">Real Estate</div>
							<h2 class="display-4 font-weight-bold">Empowering Real Estate Professionals.</h2>
							<p>
                                We have built a team to assist real estate professionals to save time! Your time is better spent finding the next listing or closing a deal. Learn how we have helped Real Estate professionals below:
                            </p>
							<a href="#contact" class="button button-rounded button-large nott ls0">Get Started</a>
						</div>
						<div class="col-lg-6 mt-4 mt-lg-0">
							<img src="/assets/images/services/landing1.jpeg" alt="Image">
						</div>
					</div>
				</div>
				<!-- Features
				============================================= -->
				<div class="section mb-0">
					<div class="container">
						<div class="heading-block border-bottom-0 center">
							<div class="badge badge-pill badge-default">Services</div>
							<h3 class="nott ls0">What We Can Do</h3>
						</div>
						<div class="row col-mb-30 align-content-stretch">

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/realestate/multichannel.png" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Multi-channel listings update</h3>
												<p>
                                                    Real estate professionals must market to everyone, which means your listing may be on multiple platforms. Updating every listing on every marketing channel is time-consuming. Save time by having eFlex update all of your listings across your channels.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/realestate/dbmanagement.png" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Database and property management</h3>
												<p>
                                                    Data is an integral part of any business today. There are thousands of data management tools to choose from, yet, you are still responsible for managing the data within those tools. Let us take that burden from you.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/realestate/mmc.png" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Marketing material creation</h3>
												<p>
                                                    Slides, decks, and Powerpoint presentations are all necessary tools to utilize when making your marketing materials. Still, even with preset templates for your company, this task can take hours to complete. You can have our digital artists and marketers assist you with this task to save you hundreds of hours per year. 
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/realestate/followup.png" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Follow up and appointment setting</h3>
												<p>
                                                    Our team can follow up with partners and potential sellers. They can also manage calendars and set appointments for you, giving you more time to focus on your core business.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/realestate/datamining.png" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Data mining</h3>
												<p>
                                                    Researching market data, partner data, and property data can take hours out of your day. We have a team dedicated to this exact type of task, working around the clock. Tap into our resources and get data to your fingertips without sacrificing your business.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 d-flex">
								<div class="card">
									<div class="card-body p-5">
										<div class="feature-box flex-column">
											<div class="mb-3">
												<img src="/assets/images/services/realestate/reporting.png" alt="Feature Icon" class="bg-transparent rounded-0">
											</div>
											<div class="fbox-content">
												<h3 class="nott ls0 text-larger">Reporting</h3>
												<p>
                                                    Whether you're a one-person team or part of a larger organization, you must have a pulse on operations. eFlex will provide you with weekly reports on highlights, lowlights, and everything in between to help you reach better business decisions.
                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
                <!-- Pricing
				============================================= -->
                <div id="pricing" class="section m-0" style="background: url('/assets/images/sections/4.png') no-repeat center top; background-size: cover; padding: 140px 0 0;">
					<div class="container">
						<div class="row justify-content-between">
							<div class="col-lg-3 mt-4">
								<div class="heading-block border-bottom-0 bottommargin-sm">
									<div class="badge badge-pill badge-default">Pricing Table</div>
									<h3 class="nott ls0">Choose your plan. </h3>
								</div>
								<p>Businesses come in different sizes and we can tailor fit the service to fit your needs. Please use this table as a guideline and know that we can customize if need be.</p>
								<div class="pricing-tenure-switcher d-flex align-items-center mb-4 position-relative" data-container="#pricing-switch">
								</div>
							</div>

							<div class="col-lg-9">
								<div id="section-pricing" class="page-section p-0 m-0">

									<div id="pricing-switch" class="pricing row align-items-end no-gutters col-mb-50 mb-4">

										<div class="col-md-4">

											<div class="pricing-box">
												<div class="pricing-title">
													<img class="mb-2 bg-transparent rounded-0" src="/assets/images/icons/group.svg" alt="Pricing Icon" width="60">
													<h3>Shared Resource</h3>
                                                    <span>For those that need less than 20 hours of assistance per week</span>
												</div>
												<div class="pricing-price">
													<div class="pts-switch-content-left"><span class="price-unit">Starting at: </span><span>&dollar;</span>899<span class="price-tenure">Per Month</span></div>
													<div class="pts-switch-content-right"><span class="price-tenure">Per Virtual Agent</span></div>
												</div>
												<div class="pricing-features border-0 bg-transparent">
													<ul>
														<li><i class="icon-check-circle color mr-2"></i><strong>A Team of Virtual Assistants</strong>  that will learn your tasks and carry them out effectively</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>A Shared Manager</strong> is included</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>Flexible Hours</strong> to ensure on-time delivery</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>Tasks Are Done</strong> from a group of virtual assistants</li>
													</ul>
												</div>
												<div class="pricing-action">
													<div class="pts-switch-content-left">
                                                        <router-link to="/shared" class="button button-rounded button-large button-light text-dark bg-white border btn-block nott m-0 ls0">Get Started</router-link>
                                                    </div>
												</div>
											</div>

										</div>

                                        <div class="col-md-4">

											<div class="pricing-box">
												<div class="pricing-title">
													<img class="mb-2 bg-transparent rounded-0" src="/assets/images/icons/man.svg" alt="Pricing Icon" width="50">
													<h3>Dedicated Resource</h3>
													<span>For those that need 40 hours of assistance per week</span>
												</div>
												<div class="pricing-price">
													<div class="pts-switch-content-left"><span class="price-unit">Starting at: </span><span>&dollar;</span>1,900<span class="price-tenure">Per Month</span></div>
													<div class="pts-switch-content-right"><span class="price-tenure">Per Virtual Agent</span></div>
												</div>
												<div class="pricing-features border-0 bg-transparent">
													<ul>
														<li><i class="icon-check-circle color mr-2"></i><strong>Dedicated Team Member</strong> to learn the ins and outs of your processes</li>
														<li class="pts-switch-content-right"><i class="icon-check-circle color mr-2"></i><strong>A Shared Manager</strong> is included</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>Flexible Hours</strong> to coincide with your schedule</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>Recruited Specifically</strong>  for your use case</li>
													</ul>
												</div>
												<div class="pricing-action">
													<div class="pts-switch-content-right">
														<router-link to="/dedicated" class="button button-large button-rounded btn-block text-capitalize m-0 ls0">Get Started</router-link>
													</div>
												</div>
											</div>

										</div>

                                        <div class="col-md-4">

											<div class="pricing-box">
												<div class="pricing-title">
													<img class="mb-2 bg-transparent rounded-0" src="/assets/images/icons/group.svg" alt="Pricing Icon" width="60">
													<img class="mb-2 bg-transparent rounded-0" src="/assets/images/icons/man.svg" alt="Pricing Icon" width="50">
                                                    <h3>Custom Built</h3>
                                                    <span>For those that need an outside-of-the-box solution</span>
												</div>
												<div class="pricing-price">
													<div class="pts-switch-content-left"><span class="price-unit">Let's Connect: </span>Call Us<span class="price-tenure"></span></div>
                                                    <div class="pts-switch-content-right"><span class="price-tenure">Per Month</span></div>
                                                    <div class="pts-switch-content-right"><span class="price-tenure">Flat Rate</span></div>
												</div>
												<div class="pricing-features border-0 bg-transparent">
													<ul>
														<li><i class="icon-check-circle color mr-2"></i><strong>Multiple Team Members</strong> to process different parts of your business</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>A Dedicated Project Manager</strong> is included</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>Flexible Hours</strong> to coincide with your schedule</li>
														<li><i class="icon-check-circle color mr-2"></i><strong>Recruited Specifically</strong> for your use case</li>
													</ul>
												</div>
												<div class="pricing-action">
													<div class="pts-switch-content-left">
                                                        <router-link to="/custom" class="button button-rounded button-large button-light text-dark bg-white border btn-block nott m-0 ls0">Get Started</router-link>
                                                    </div>
												</div>
											</div>

										</div>
									</div>

								</div>
							</div>

						</div>
					</div>
				</div>

				<div id="contact">
					<Contact/>
				</div>
				
			</div>
	</section>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/ContactUs.vue'

export default {
	name: 'realestate',
	data() {
		return {

		}
	},
	components: {
		Contact
	},
	computed: {
		
	},
	methods: {
		
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>
